import "./MainBanner.css";
import flower from '../../../../assets/images/main-flower.webp'
import rLeaf from '../../../../assets/images/rLeaf.webp'
import lLeaf from '../../../../assets/images/lLeaf.webp'
import flowerMobile from '../../../../assets/images/main-flower-mobile.webp'

function MainBanner({ }) {

    return (
        <>
            <div className={`main-banner`} >
                <img src={rLeaf} alt="" className="main-banner__img_leaf-rigth"></img>
                <img src={lLeaf} alt="" className="main-banner__img_leaf-left"></img>
                <p className="main-banner__title">Мир парфюмера</p>
                <img src={flower} alt="" className="main-banner__img_flower"></img>
                <img src={flowerMobile} alt="" className="main-banner__img_flower-mobile"></img>
            </div >
            <div className="main-banner_bg"></div>
        </>
    );
}

export default MainBanner;