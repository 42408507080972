import React, { useState, useEffect } from 'react';
import './ImageWithBackground.css';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

const defaultAspectRatios = {
    '16x10': 16 / 10,
    '10x16': 10 / 16,
    '1x1': 1 / 1,
};

const ImageWithBackground = ({ src, image_radius, className, image_format, metadata }) => {

    const isAspectRatioSignificantlyDifferent = () => {
        const defaultAspectRatio = defaultAspectRatios[image_format];
        const tolerance = 0.3; // Define tolerance for aspect ratio difference

        // console.log(Math.abs(defaultAspectRatio - (metadata.extra.size.width / metadata.extra.size.height)), (metadata.extra.size.width / metadata.extra.size.height))
        return metadata ? Math.abs(defaultAspectRatio - (metadata.extra.size.width / metadata.extra.size.height)) > tolerance : false;
    };

    const imageStyle = isAspectRatioSignificantlyDifferent()
        ?
        metadata.extra.size.width > metadata.extra.size.height ?
            {
                width: '100%',
                height: 'unset',
                objectFit: 'cover',
            }
            :
            {
                width: 'unset',
                height: '100%',
                objectFit: 'cover',
            }
        : { width: '100%', height: '100%', objectFit: 'cover' };

    return (
        <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: image_radius, }}>
            <img className='image-with-background__img' style={imageStyle} src={src} alt="" />
        </div>
    );
};

export default ImageWithBackground;