import { useContext, useEffect, useState } from 'react';
import './ProductCard.css';
import { ConfigContext } from '../../../assets/contexts/configContext';
import { Link, useNavigate } from 'react-router-dom';
import { CART_MAIN_LINK, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from '../../../assets/utils/constants';
import { buildCategoryPath, compareOptions, getMinPrice, parsePrice } from '../../../assets/utils/utils';
import { CartIcon } from '../../../assets/icons/icons';
import ImageWithBackground from '../../ImageWithBackground/ImageWithBackground';
import { CartContext } from '../../../assets/contexts/cartContext';

const CARD_LAYOUT_TYPES = {
    TITLE: 'item_name',
    DESCRIPTION: 'description',
    IMAGE: 'image',
    BUTTON: 'price',

}

const PRICE_FORMATS = {
    SEPARATELY: 'separately',
    ON_BUTTON: 'on_button',
}

const BUY_BTN_FORMATS = {
    TEXT: 'text',
    BUTTON: 'button',
}

function ProductCard({
    item, path
}) {

    const config = useContext(ConfigContext)
    const { cardTokens, initialConstants } = config
    const { CONSTANTS } = initialConstants
    const { ENABLE_PHOTO_AUTOADJUST = true } = CONSTANTS
    const {
        // cards_in_line,
        but_btn_format,
        image_format,
        image_radius,
        layout,
        price_format,
    } = cardTokens
    // Reverse the array and store it
    const reversedArray = [...item.categories[0].parents].reverse();

    // const [bgColor, setBgColor] = useState('transparent');

    // useEffect(() => {
    //     Vibrant.from(src).getPalette()
    //       .then(palette => {
    //         const rgbColor = palette.Vibrant?.getRgb();
    //         if (rgbColor) {
    //           setBgColor(`rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`);
    //         }
    //       })
    //       .catch(err => console.error('Error extracting color:', err));
    //   }, [src]);

    const cart_context = useContext(CartContext)
    const {
        cart,
        handleAddToCart,
        handleRemoveFromCart,
        // setCart,
    } = cart_context


    const inCart = item && cart && cart.find((cart_item) => {
        if (cart_item._id !== item._id) {
            return false; // Skip items with a different ID early
        }
        // If the item has options and selectedOptions are provided, compare them
        if (cart_item.options && cart_item.options.length > 0) {
            return false
        } else {
            // If there are no options to compare, just check the _id
            return true;
        }
    });
    const navigate = useNavigate()
    const in_stock = item ? item.options.length === 1 ? true : item.price_data.in_stock : null
    function handleCartClick(e) {
        if (!in_stock) return
        if (!item.options || item.options.length === 0) {
            e.preventDefault(); // Prevent default button action
            e.stopPropagation(); // Stop the event from bubbling up
            if (inCart) {
                navigate(`/${CART_MAIN_LINK}`);
                return;
            }
            console.log(item)
            const product_to_card = {
                ...item,
                options: null,
                // selected_size: selectedSize,
                count: 1,
            };
            console.log(product_to_card);
            handleAddToCart(product_to_card);
        }
    }


    const in_cart_text = inCart ? 'В корзине ›' : 'В корзину'
    const price = item.options && item.options.length === 1 ? getMinPrice(item.options[0].values) : item.price_data.price

    return (
        <Link className='product-card' to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(reversedArray, item.categories[0].parents.length - 1)}/${CATALOG_ITEM_LINK}/${item._id}`}>
            {item.price_data.discount && item.price_data.discount > 0 ?
                <h3 className='product-card__price-discount'>-{item.price_data.discount}%</h3>
                :
                null
            }
            {ENABLE_PHOTO_AUTOADJUST ?
                < ImageWithBackground
                    className={`product-card__image product-card__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    image_format={image_format}
                    key={`${item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}${item._id}`}
                    metadata={item.files && item.files.length > 0 ? item.files[0].metadata : null}
                />
                :
                <img
                    className={`product-card__image product-card__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    alt=''
                ></img>
            }
            <h3 className={`product-card__title`}>{item.name && item.name.length > 0 ? item.name : ` `}</h3>
            <p className={`product-card__description`}>{item.description && item.description.length > 0 ? item.description : ` `}</p>
            {/* <div className='product-card__separator'></div> */}
            <button className={`product-card__button   `} type='button' onClick={(e) => handleCartClick(e)}>
                {item.price_data.discount && item.price_data.discount > 0 ?
                    <h3 className='product-card__button-price product-card__button-price_discount'><span className='product-card__button-price-discounted'>{parsePrice(Number(price), 'RUB')}</span> {item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(Math.round(Number(price) - Number(price) / 100 * Number(item.price_data.discount)), 'RUB')}</h3>
                    :
                    <h3 className='product-card__button-price product-card__button-price_discount'><span className='product-card__button-price-discounted product-card__button-price-discounted_unvisible'>{0}</span> {item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(Math.round(Number(price) - Number(price) / 100 * Number(item.price_data.discount)), 'RUB')}</h3>

                    // <h3 className='product-card__button-price'>{item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(price, 'RUB')}</h3>
                }

                {in_stock ?
                    <div className={`product-card__button-to-cart ${inCart ? "product-card__button_in-cart" : ""}`}>
                        <p className='product-card__button-to-cart-text'>{in_cart_text}</p>
                    </div>
                    :
                    <div className='product-card__button-to-cart product-card__button-to-cart_not-in-stok'>
                        <p className='product-card__button-to-cart-text'>Нет в наличии</p>
                    </div>
                }

            </button>
        </Link>
    );
}

export default ProductCard