import { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import { ConfigContext } from "../../assets/contexts/configContext";
import { Link, NavLink } from "react-router-dom";
import {
  CART_MAIN_LINK,
  CATALOG_MAIN_LINK,
} from "../../assets/utils/constants";
import { CartIcon, CloseIcon, MenuIcon, SearchIcon } from "../../assets/icons/icons";
import mainApi from "../../assets/api/MainApi";
import CatalogDrop from "./CatalogDrop/CatalogDrop";
import MenuPopup from "./MenuPopup/MenuPopup";
import { CartContext } from "../../assets/contexts/cartContext";
import useScrollDirection from "../../assets/hooks/useScrollDirection";
import { getURLRelativePath, isExternalURL } from "../../assets/utils/utils";
import Search from "../Search/Search";
import useWindowSize from "../../assets/hooks/useWindowSize";

function Header() {
  const scrollDirection = useScrollDirection();
  const { width } = useWindowSize();
  const [searchOpen, setSearchOpen] = useState(false)

  const config = useContext(ConfigContext);
  const cart_context = useContext(CartContext)
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
  } = cart_context
  const { initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES, HEADER_LINKS } = initialConstants

  const catalogBtnRef = useRef(null);
  const linksRef = useRef(null);

  const [isCatalogOpen, setCatalogOpen] = useState(false);
  // const [categories, setCategories] = useState(FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 && Array.isArray(FIRST_LEVEL_CATEGORIES) ? FIRST_LEVEL_CATEGORIES : []);
  const [topCategories, setTopCategories] = useState(undefined);

  // useEffect(() => {
  //   mainApi
  //     .getCategories({
  //       limit: 25,
  //       shop_id: "65ae789bcb5f6a9f66e457aa",
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setCategories(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
      }, 10);

      console.log(window.pageYOffset);
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.width = "unset";
      document.body.style.height = "unset";
      document.body.style.top = `unset`;
      console.log(popupScrollPosition);
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const [visibleCategories, setVisibleCategories] = useState([]);

  useEffect(() => {
    if (!HEADER_LINKS || HEADER_LINKS.length === 0) return
    const updateVisibleLinks = () => {
      if (linksRef.current) {
        const maxAllowedWidth = linksRef.current.offsetWidth;
        if (maxAllowedWidth <= 0) return
        let totalWidth = 0;
        const linksToShow = [];

        HEADER_LINKS.forEach((link, index) => {
          const linkElement = document.createElement('div');
          linkElement.className = 'link';
          linkElement.style.visibility = 'hidden';
          linkElement.style.position = 'absolute';
          linkElement.innerText = link.name;
          document.body.appendChild(linkElement);
          const linkWidth = linkElement.offsetWidth + 28 * 2;
          document.body.removeChild(linkElement);

          if (totalWidth + linkWidth <= maxAllowedWidth) {
            linksToShow.push(link);
            totalWidth += linkWidth;
          }
        });

        setVisibleCategories(linksToShow);
      }
    };

    updateVisibleLinks();
    window.addEventListener('resize', updateVisibleLinks);

    return () => window.removeEventListener('resize', updateVisibleLinks);
  }, [HEADER_LINKS]);

  return (
    <>

      <header className={`header  ${scrollDirection === "down" ? searchOpen ? "header_show" : "header_hide" : "header_show"}`}>
        <button
          className="header__menu"
          type="button"
          onClick={() => {
            setMenuOpen(true);
          }}
        >
          <MenuIcon
            mainClassName={"header__menu-icon"}
            fillClassName={"header__menu-icon-fill"}
          />
        </button>
        <CatalogDrop
          isOpened={isCatalogOpen}
          setOpened={setCatalogOpen}
          categories={FIRST_LEVEL_CATEGORIES}
          catalogBtnRef={catalogBtnRef}
        />
        <MenuPopup
          isOpened={isMenuOpen}
          setOpened={setMenuOpen}
          categories={FIRST_LEVEL_CATEGORIES}
        />
        <div className="header__content">
          <Link className="header__logo header__logo_pc" to={"/"}>
            <svg className="header__logo-img" width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9998 27.2224C14.3977 27.2224 14.7597 27.4535 14.928 27.8152L17.155 32.6003C17.3176 32.9497 17.2714 33.3609 17.0353 33.6653L14.8083 36.5364C14.6143 36.7866 14.3159 36.9329 13.9998 36.9329C13.6837 36.9329 13.3853 36.7866 13.1912 36.5364L10.9642 33.6653C10.7281 33.3609 10.6819 32.9497 10.8445 32.6003L13.0715 27.8152C13.2398 27.4535 13.6018 27.2224 13.9998 27.2224ZM12.9659 32.8999L13.9998 34.2327L15.0336 32.8999L13.9998 30.6784L12.9659 32.8999Z" fill="#FF70A4" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83774 22.6241C5.401 22.6241 5.85761 23.086 5.85761 23.6558V37.9367H22.1428V23.6558C22.1428 23.086 22.5994 22.6241 23.1626 22.6241C23.7259 22.6241 24.1825 23.086 24.1825 23.6558V38.9683C24.1825 39.5381 23.7259 40 23.1626 40H4.83774C4.27448 40 3.81787 39.5381 3.81787 38.9683V23.6558C3.81787 23.086 4.27448 22.6241 4.83774 22.6241Z" fill="#C187FF" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9994 0.425415C14.3763 0.425415 14.7227 0.632887 14.9013 0.965585L20.2461 10.9187C20.4384 11.2769 20.4018 11.7151 20.1528 12.0362L14.808 18.9269C14.6139 19.1771 14.3155 19.3234 13.9994 19.3234C13.6833 19.3234 13.3849 19.1771 13.1909 18.9269L7.8461 12.0362C7.59702 11.7151 7.56041 11.2769 7.75276 10.9187L13.0975 0.965585C13.2762 0.632886 13.6226 0.425415 13.9994 0.425415ZM9.873 11.3033L13.9994 16.6232L18.1259 11.3033L13.9994 3.61905L9.873 11.3033Z" fill="#FF8170" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.709287 8.89173C1.13182 8.75507 1.59322 8.91038 1.85057 9.27589L14 26.5316L26.1494 9.27589C26.4068 8.91038 26.8682 8.75507 27.2907 8.89173C27.7132 9.02838 28 9.42566 28 9.8744V38.968C28 39.5378 27.5434 39.9997 26.9801 39.9997C26.4169 39.9997 25.9603 39.5378 25.9603 38.968V13.1013L14.8307 28.9085C14.6393 29.1803 14.3299 29.3417 14 29.3417C13.6701 29.3417 13.3607 29.1803 13.1693 28.9085L2.03974 13.1013V38.968C2.03974 39.5378 1.58312 39.9997 1.01987 39.9997C0.45661 39.9997 0 39.5378 0 38.968V9.8744C0 9.42566 0.286751 9.02838 0.709287 8.89173Z" fill="#FF87F3" />
            </svg>

            <img
              className="header__logo-img"
              src={config.platfromTokens.logo.url}
              alt=""
            />
          </Link>

          <Link className="header__logo header__logo_mobile" to={"/"}>
            <svg className="header__logo-img" width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9998 27.2224C14.3977 27.2224 14.7597 27.4535 14.928 27.8152L17.155 32.6003C17.3176 32.9497 17.2714 33.3609 17.0353 33.6653L14.8083 36.5364C14.6143 36.7866 14.3159 36.9329 13.9998 36.9329C13.6837 36.9329 13.3853 36.7866 13.1912 36.5364L10.9642 33.6653C10.7281 33.3609 10.6819 32.9497 10.8445 32.6003L13.0715 27.8152C13.2398 27.4535 13.6018 27.2224 13.9998 27.2224ZM12.9659 32.8999L13.9998 34.2327L15.0336 32.8999L13.9998 30.6784L12.9659 32.8999Z" fill="#FF70A4" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83774 22.6241C5.401 22.6241 5.85761 23.086 5.85761 23.6558V37.9367H22.1428V23.6558C22.1428 23.086 22.5994 22.6241 23.1626 22.6241C23.7259 22.6241 24.1825 23.086 24.1825 23.6558V38.9683C24.1825 39.5381 23.7259 40 23.1626 40H4.83774C4.27448 40 3.81787 39.5381 3.81787 38.9683V23.6558C3.81787 23.086 4.27448 22.6241 4.83774 22.6241Z" fill="#C187FF" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9994 0.425415C14.3763 0.425415 14.7227 0.632887 14.9013 0.965585L20.2461 10.9187C20.4384 11.2769 20.4018 11.7151 20.1528 12.0362L14.808 18.9269C14.6139 19.1771 14.3155 19.3234 13.9994 19.3234C13.6833 19.3234 13.3849 19.1771 13.1909 18.9269L7.8461 12.0362C7.59702 11.7151 7.56041 11.2769 7.75276 10.9187L13.0975 0.965585C13.2762 0.632886 13.6226 0.425415 13.9994 0.425415ZM9.873 11.3033L13.9994 16.6232L18.1259 11.3033L13.9994 3.61905L9.873 11.3033Z" fill="#FF8170" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.709287 8.89173C1.13182 8.75507 1.59322 8.91038 1.85057 9.27589L14 26.5316L26.1494 9.27589C26.4068 8.91038 26.8682 8.75507 27.2907 8.89173C27.7132 9.02838 28 9.42566 28 9.8744V38.968C28 39.5378 27.5434 39.9997 26.9801 39.9997C26.4169 39.9997 25.9603 39.5378 25.9603 38.968V13.1013L14.8307 28.9085C14.6393 29.1803 14.3299 29.3417 14 29.3417C13.6701 29.3417 13.3607 29.1803 13.1693 28.9085L2.03974 13.1013V38.968C2.03974 39.5378 1.58312 39.9997 1.01987 39.9997C0.45661 39.9997 0 39.5378 0 38.968V9.8744C0 9.42566 0.286751 9.02838 0.709287 8.89173Z" fill="#FF87F3" />
            </svg>
          </Link>

          <div className="header__catalog-with-links">
            <div className="header__catalog">
              {/* <div className='header__categories'>
                        {categories.map((category, i) => (
                            <Link className='header__category' to={`/${CATALOG_MAIN_LINK}/${category.translit_name}`}>
                                {category.title}
                            </Link>
                        ))}
                    </div> */}
              {/* <button className='header__catalog-btn' type='button'>
                        <h3 className='header__catalog-btn-text'>Каталог товаров</h3>
                    </button> */}

              <button
                className="header__catalog-btn"
                type="button"
                ref={catalogBtnRef}
              >
                {/* <CloseIcon
                  mainClassName={`header__catalog-btn-icon header__catalog-btn-icon_close-catalog ${isCatalogOpen
                    ? "header__catalog-btn-icon_close-catalog-open"
                    : ""
                    }`}
                  fillClassName={"header__catalog-btn-icon-fill"}
                /> */}
                {/* <svg
                  className={`header__catalog-btn-icon`}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    className={`header__catalog-btn-icon-fill`}
                    y="6"
                    width="20"
                    height="2"
                    rx="1"
                    fill="white"
                  />
                  <rect
                    className="header__catalog-btn-icon-fill"
                    y="12"
                    width="13"
                    height="2"
                    rx="1"
                    fill="white"
                  />
                </svg> */}
                <h3 className="header__catalog-btn-text">Каталог</h3>
                <svg className={`header__catalog-btn-icon header__catalog-btn-icon_open-catalog ${!isCatalogOpen
                  ? "header__catalog-btn-icon_close-catalog-open"
                  : ""
                  }`} xmlns="http://www.w3.org/2000/svg" width="8" height="22" viewBox="0 0 8 22" fill="none">
                  <path d="M1 11L3.85858 13.8586C3.93668 13.9367 4.06332 13.9367 4.14142 13.8586L7 11" stroke="#B566D1" stroke-width="1.4" stroke-linecap="round" />
                </svg>
                {/* <CloseIcon
                  mainClassName={`header__catalog-btn-icon header__catalog-btn-icon_close-catalog ${isCatalogOpen
                    ? "header__catalog-btn-icon_close-catalog-open"
                    : ""
                    }`}
                  fillClassName={"header__catalog-btn-icon-fill"}
                /> */}

                <svg className={`header__catalog-btn-icon header__catalog-btn-icon_close-catalog ${isCatalogOpen
                  ? "header__catalog-btn-icon_close-catalog-open"
                  : ""
                  }`} xmlns="http://www.w3.org/2000/svg" width="8" height="22" viewBox="0 0 8 22" fill="none">
                  <path d="M1 11L3.85858 13.8586C3.93668 13.9367 4.06332 13.9367 4.14142 13.8586L7 11" stroke="#B566D1" stroke-width="1.4" stroke-linecap="round" />
                </svg>
              </button>
            </div>
            <div className="search_pc">
              <button className="header__input-icon" onClick={() => setSearchOpen(true)} type="button">
                <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
              </button>
              <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={linksRef && linksRef.current ? linksRef.current.offsetWidth + 28 + 20 : '700px'} />
            </div>

            {HEADER_LINKS && HEADER_LINKS.length > 0 ?
              <div className="header__links" ref={linksRef}>
                {visibleCategories.map((link) => {
                  if (isExternalURL(link.url)) {
                    return (
                      <Link
                        key={link._id}
                        className={`header__link`}
                        to={link.url}
                        target={'_blank'} // Conditionally set target attribute
                        rel={'noopener noreferrer'} // Add rel for security if external
                      >
                        {link.name}
                      </Link>
                    )
                  } else {
                    const relativePath = getURLRelativePath(link.url);
                    return (
                      <NavLink
                        key={link._id}
                        className={({ isActive }) => {
                          return `header__link ${isActive ? 'header__link_active' : ''}`
                        }}
                        to={relativePath}
                      >
                        {link.name}
                        <svg className="header__link__active-triangle" xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0L0 14L10 28L20 14L10 0Z" fill="#EDE0F9" />
                        </svg>
                      </NavLink>
                    )
                  }
                })}
              </div>
              : null}
          </div>

          <div className="header__cart-and-menu">
            <button className="header__search-icon-mobile" onClick={() => setSearchOpen(true)} type="button">
              <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
            </button>
            <div className="header__cart-box">
              {cart && cart.length > 0 ? (
                <p className="header__cart-count">{cart.length}</p>
              ) : null}
              <Link className="header__cart" to={`/${CART_MAIN_LINK}`}>
                {/* <h3 className="header__cart-text">Корзина</h3> */}
                <CartIcon
                  mainClassName={"header__cart-icon"}
                  fillClassName={"header__cart-icon-fill"}
                />
              </Link>
            </div>
            {/* <button
              className="header__menu"
              type="button"
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <MenuIcon
                mainClassName={"header__menu-icon"}
                fillClassName={"header__menu-icon-fill"}
              />
            </button> */}
          </div>

          <div className="search_mobile">

            <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={`100%`} />
          </div>

        </div>
      </header>
    </>
  );
}

export default Header;
