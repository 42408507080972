import "./PromoLine.css";
import promo1 from '../../../../assets/images/promo1.webp'
import promo2 from '../../../../assets/images/promo2.webp'
import promo3 from '../../../../assets/images/promo3.webp'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

function PromoLine({ }) {
    const PROMO = [
        {
            title: 'Аромат</br> месяца',
            img: promo1,
            link: '/catalog/aromaty-mesjatsa-b0pk'
        },
        {
            title: 'Сезонные</br> скидки',
            img: promo2,
            link: '/catalog/sezonnye-skidki-8pwb'
        },
        {
            title: 'Хиты</br> продаж',
            img: promo3,
            full: true,
            link: '/catalog/hity-prodazh-qd7m'
        },
    ]
    return (
        <>
            <div className="promoline">
                {PROMO.map((item, i) => {
                    return (
                        <Link to={item.link} className="promoline__card">
                            <div className="promoline__card__text">
                                <div>
                                    <p className={`promoline__card__title ${i === 1 ? 'promoline__card__title_blue' : i === 2 ? 'promoline__card__title_roze' : null}`} dangerouslySetInnerHTML={{ __html: item.title }} />
                                </div>
                            </div>
                            <img src={item.img} alt="" className={`promoline__card__img ${i === 1 ? 'promoline__card__img_big' : ''}`}></img>
                            {i === 0 ?
                                <svg className="promoline__card__circle-for-first" xmlns="http://www.w3.org/2000/svg" width="394" height="220" viewBox="0 0 394 220" fill="none">
                                    <g filter="url(#filter0_f_2099_19)">
                                        <circle cx="294" cy="105" r="100" fill="#DDE0FF" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_2099_19" x="0" y="-189" width="588" height="588" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="97" result="effect1_foregroundBlur_2099_19" />
                                        </filter>
                                    </defs>
                                </svg> : null}
                        </Link>
                    )
                })}
            </div>
            <Swiper
                slidesPerView={'auto'}
                // loop={true}
                spaceBetween={8}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Navigation, Pagination]}
                preventInteractionOnTransition={true}
                className="promoline promoline__swiper"
            >
                {PROMO.map((item, i) => {
                    return (
                        <SwiperSlide className="promoline__card-box">
                            <Link to={item.link} className="promoline__card">
                                <div className="promoline__card__text">
                                    <div>
                                        <p className={`promoline__card__title ${i === 1 ? 'promoline__card__title_blue' : i === 2 ? 'promoline__card__title_roze' : null}`} dangerouslySetInnerHTML={{ __html: item.title }} />
                                    </div>
                                </div>
                                <img src={item.img} alt="" className={`promoline__card__img ${i === 1 ? 'promoline__card__img_big' : ''}`}></img>
                                {i === 0 ?
                                    <svg className="promoline__card__circle-for-first" xmlns="http://www.w3.org/2000/svg" width="394" height="220" viewBox="0 0 394 220" fill="none">
                                        <g filter="url(#filter0_f_2099_19)">
                                            <circle cx="294" cy="105" r="100" fill="#DDE0FF" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_f_2099_19" x="0" y="-189" width="588" height="588" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                <feGaussianBlur stdDeviation="97" result="effect1_foregroundBlur_2099_19" />
                                            </filter>
                                        </defs>
                                    </svg> : null}
                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    );
}

export default PromoLine;