import "./Brands.css";
import brand1 from '../../../../assets/images/hollister.webp'
import brand2 from '../../../../assets/images/amouge.webp'
import brand3 from '../../../../assets/images/alexandre.webp'
import brand4 from '../../../../assets/images/fitch.webp'
import brand5 from '../../../../assets/images/afnan.webp'
import brand6 from '../../../../assets/images/haramain.webp'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { CATALOG_MAIN_LINK } from "../../../../assets/utils/constants";

function Brands({ }) {
    const BRANDS = [
        {
            name: 'Hollister',
            logo: brand1,
            path: '/brendy-eo6k/hollister-smzy',
        },
        {
            name: 'Amouage',
            logo: brand2,
            path: '/brendy-eo6k/amouage-9rnj',
        },
        {
            name: 'Alexandre.J',
            logo: brand3,
            path: '/brendy-eo6k',
        },
        {
            name: 'Abercrombie & Fitch',
            logo: brand4,
            path: '/brendy-eo6k/abercrombie---fitch-kzqa',
        },
        {
            name: 'Afan Perfumes',
            logo: brand5,
            path: '/brendy-eo6k/afnan-6iig',
        },
        {
            name: 'Al Haramain',
            logo: brand6,
            path: '/brendy-eo6k/al-haramain-zurx',
        },
    ]

    const NUMBER_OF_SLIDE = Math.round(BRANDS.length / 4)

    return (
        <div className="brands">
            <div className="brands__title-and-btn">
                <p className="brands__title">Бренды</p>
                <Link to={`${CATALOG_MAIN_LINK}/brendy-eo6k`} className='brands__btn brands__btn_pc'>Все бренды <span>→</span></Link>
            </div>

            <div className="brands__cards">
                {BRANDS.map((item, i) => {
                    return (
                        <div className="brands__card__box">
                            <Link className="brands__card" to={`/${CATALOG_MAIN_LINK}${item.path}`}>
                                <img src={item.logo} alt="" className={`brands__card__img brands__card__img-${i}`}></img>
                                <p className="brands__card__name">{item.name}</p>
                            </Link>
                            {i + 1 !== BRANDS.length ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="130" viewBox="0 0 2 130" fill="none">
                                    <path opacity="0.3" d="M1 1V129" stroke="#A196C0" stroke-linecap="round" />
                                </svg>
                                : null}
                        </div>
                    )
                })}
            </div>

            <Swiper
                slidesPerView={'auto'}
                // loop={true}
                spaceBetween={8}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Navigation, Pagination]}
                preventInteractionOnTransition={true}
                className="brands__swiper"
            >
                <SwiperSlide className="brands__cards__swiper">
                    {BRANDS.slice(0, 4).map((item, i) => {
                        return (
                            <>
                                <Link className="brands__card" to={`/${CATALOG_MAIN_LINK}${item.path}`}>
                                    <img src={item.logo} alt="" className={`brands__card__img brands__card__img-${i}`}></img>
                                    <p className="brands__card__name">{item.name}</p>
                                </Link>
                            </>
                        )
                    })}
                </SwiperSlide>
                <SwiperSlide className="brands__cards__swiper">
                    {BRANDS.slice(4, BRANDS.length).map((item, i) => {
                        return (
                            <>
                                <Link className="brands__card" to={`/${CATALOG_MAIN_LINK}${item.path}`}>
                                    <img src={item.logo} alt="" className={`brands__card__img brands__card__img-${i}`}></img>
                                    <p className="brands__card__name">{item.name}</p>
                                </Link>
                            </>
                        )
                    })}
                </SwiperSlide>
            </Swiper>
            <Link to={`${CATALOG_MAIN_LINK}/brendy-eo6k`} className='brands__btn brands__btn_mobile'>Все бренды <span>→</span></Link>
        </div>
    );
}

export default Brands;